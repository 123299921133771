.billing {
  display: flex;
  height: 100vh;
}

.billing-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  
  &__main {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;

    div.header {
      padding: 15px 0px 15px 0px;
    }
  }
}

.billing-content-section {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin: 100px 0px 5px 0px;

  &__buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
  }

  &__buttons-add {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
}

.billing-content-table {
  margin-top: 20px;
  box-sizing: border-box;
}