.policy-owners-details {
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;

    overflow: auto;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    div.header {
      padding: 15px 0px 15px 0px;
    }
  
    // @media (max-height: 580px) {
    //   background: white;
    //   padding: 13.4px;
    //   padding-left: 50px;
    //   padding-right: 50px;
    // }
  
    // @media (min-height: 580px) and (max-height: 650px) {
    //   background: white;
    //   padding: 16px;
    //   padding-left: 50px;
    //   padding-right: 50px;
    // }
  
    // @media (min-height: 650px) and (max-height: 720px) {
    //   background: white;
    //   padding-left: 8px;
    //   padding-right: 8px;
    // }
  
    // @media (min-height: 720px) and (max-height: 1000px) {
    //   background: white;
    //   padding: 20px;
    //   padding-left: 50px;
    //   padding-right: 50px;
    // }
  
    // @media (min-height: 1000px) and (max-height: 1500px) {
    //   background: white;
    //   padding: 22px;
    //   padding-left: 50px;
    //   padding-right: 50px;
    // }
  
    // @media (min-height: 1500px) and (max-height: 1900px) {
    //   height: 100vh;
    //   background: white;
    //   padding: 24px;
    //   padding-left: 55px;
    //   padding-right: 55px;
    // }

    &--main-panel {
      margin: 0;
      // min-height: 100vh;
    }
  }

  &__consult {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    justify-content: space-between;
    width: 100%;

    &--presentation-container {
      display: flex;
      flex-direction: row;
      column-gap: 100px;
      padding: 30px 20px;
    }

    &--button-container {
      display: flex;
      flex-direction: row;
      column-gap: 40px;
    }

    &--title {
      color: var(--color-grey);

      h1 {
        font-size: 18px;
      }
    }

    &--filter {
      display: flex;
      flex-direction: column;
      padding: 30px 20px;

      span {
        font-size: 16px;
        font-family: 'Avenir Next GEO W05 Demi';
      }
    }

    &--radius-group {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      .MuiFormGroup-root {
        flex-direction: row;
      }

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }

    &--radio {
      padding: 5px;
      text-align: center;
    }
  }
}

.policy-owners-details-table {
  padding: 30px 20px;
}
