.broker-beneficiaries {
  display: flex;
  height: 100vh;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  gap: 15px;
  span {
    font-size: 1rem;
  }
}
.loading-spinner {
  margin-bottom: 15px;
  color: var(--primary-color);
}

.broker-beneficiaries-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 80px);
}

.broker-beneficiaries-consult {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 20px;
  width: 100%;

  &__title {
    font-family: 'Avenir Next GEO W05 Heavy';
    font-size: 20px;
    color: var(--color-grey);
  }
}
